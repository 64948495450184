<template>
  <div :class="{ 'nav-bar': true, fixed: fixed }">
    <div class="nav-container row row-justify-between row-align-center">
      <div class="left col-4" @click="handleLeftClick">
        <md-icon name="arrow-left"></md-icon>
        <!-- <span class="left-text">{{ leftText }}</span> -->
      </div>
      <div class="nav-title col-16">{{ title }}</div>
      <div class="right col-4" @click="$emit('rightClick')">
        <md-icon v-show="rightIcon" :name="rightIcon"></md-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    leftText: {
      type: String,
      default: '',
    },
    rightIcon: {
      type: String,
      default: '',
    },
    canBack: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleLeftClick() {
      if (this.canBack) {
        this.$router.go(-1)
      }
      this.$emit('leftClick')
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../styles/variable'
@import '../assets/theme.custom.styl'
.nav-bar
  width 100%
  z-index 99
  .nav-container
    font-size $font-lg
    font-weight bold
    color color-primary
    height 90px
    box-sizing border-box
    padding 0 20px
    background-color $gray1
    box-shadow 0 1px 3px rgba(0, 0, 0, 0.1)
    .nav-title
      height 90px
      line-height 90px
      text-align center
      white-space nowrap
      overflow hidden
      text-overflow ellipsis
    .left
      height 90px
      line-height 90px
    .right
      height 90px
      line-height 90px
      text-align right
.fixed
  position fixed
  top 0
  right 0
</style>
