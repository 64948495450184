<template>
  <div class="search row padding-lr">
    <div
      class="margin-tb-sm padding-lr search-left"
      :class="{
        'col-19': showBtn,
        'col-24': !showBtn,
      }"
    >
      <div class="row">
        <md-icon name="search" class="search-icon" svg></md-icon>
        <md-input-item
          class="padding-lr-sm"
          v-model="innerValue"
          :placeholder="placeholder"
          clearable
          @keydown="(name, e) => handleKeyDown(e)"
        ></md-input-item>
      </div>
    </div>
    <div class="margin-tb padding-left-sm search-right col-5" v-if="showBtn">
      <md-button type="primary" inline size="small" @click="$emit('onSearch', innerValue)">搜索</md-button>
    </div>
  </div>
</template>

<script>
import '@/assets/svg/search.svg'

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    showBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerValue: '',
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  },
  methods: {
    handleKeyDown(e) {
      if (e.keyCode === 13) {
        this.$emit('onSearch', this.innerValue)
      }
    },
  },
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal)
    },
    value(newVal) {
      this.innerValue = newVal
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../styles/variable'
.search
  background-color #ffffff
  .search-left
    border 1px solid $gray6
    border-radius 100px
    height 70px
    .md-input-item >>>
      width 100%
      .md-field-item-content
        min-height 0
        .md-input-item-input
          height 66px
          color $gray6
          font-size inherit
          font-weight inherit
          font-family inherit
    .search-icon
      color $gray6
      height 66px
      vertical-align top
  .search-right
    box-sizing border-box
    text-align right
</style>
