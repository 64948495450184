<template>
  <validation-provider ref="vField" :name="$attrs.title" :rules="rules" v-slot="{ errors }" :class="{ required: required }">
    <md-field-item
      v-bind="$attrs"
      v-on="$listeners"
      @click="handleClick"
      :class="{
        unrequired: !required,
        'has-error': errors.length,
      }"
    >
      <slot></slot>
    </md-field-item>
    <p class="error" v-if="errors.length > 0">{{ errors[0] }}</p>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  components: { ValidationProvider },
  methods: {
    handleClick() {
      this._validate()
      this.$emit('click')
    },
    _validate() {
      this.$refs.vField.validate(this.value)
    },
  },
  watch: {
    value() {
      this._validate()
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../styles/variable'
.required
  .md-field-item >>>
    .md-field-item-title
      &::before
        content '* '
        color $red6
>>>.md-field-item-title
  text-align left
.unrequired >>>
  .md-field-item-title
    box-sizing border-box
.error
  font-size 24px
  color $red6
  margin-top 20px
.has-error >>>
  .md-field-item-content
    &::before
      content ''
      position absolute
      z-index 2
      transform scaleY(0.5) translateY(100%)
      bottom 0
      left 0
      right auto
      top auto
      width 100%
      border-bottom solid 0.04rem #ff5257
      transform-origin 50% 100%
</style>
