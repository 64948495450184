import request from '@/utils/request'
import { stringify } from 'qs'

export function login(params) {
  return request({
    url: '/user-auth',
    method: 'POST',
    data: params,
  })
}

export function logout() {
  return request({
    url: '/user-auth',
    method: 'delete',
  })
}

export function me() {
  return request({
    url: '/me',
    method: 'get',
  })
}

export function userInfo(id, params) {
  return request({
    url: `/users/${id}/info`,
    method: 'post',
    data: params,
  })
}

export function smsCode(params) {
  return request({
    url: '/sms-code',
    method: 'post',
    data: params,
  })
}

export function codeIsExsit(params) {
  return request({
    url: `/invite_codes/is_exsit?${stringify(params)}`,
    method: 'get',
  })
}

export function upgrade(params) {
  return request({
    url: '/users/upgrade',
    method: 'post',
    data: params,
  })
}
