<template>
  <div>
    <validation-provider ref="vImgUpload" :name="title" :rules="rules" v-slot="{ errors }">
      <div class="row row-direction-column row-justify-center row-align-center">
        <p :class="{ 'image-reader-title': required }" v-if="title">{{ title }}</p>
        <p class="error" v-if="errors.length > 0">{{ errors[0] }}</p>
        <div class="row">
          <div
            class="image-reader-item-preview margin-tb-sm margin-right-sm"
            :style="{ width: `${width}px`, height: `${height}px` }"
            v-for="(item, index) in value"
            :key="index"
          >
            <img :src="item | imgSrc" @click="handleView(index)" />
            <md-tag
              class="image-reader-item-preview-del"
              size="small"
              shape="quarter"
              fill-color="#111A34"
              type="fill"
              font-color="#fff"
              @click.native="handleDeleteImage(index)"
            >
              <md-icon name="close"></md-icon>
            </md-tag>
          </div>
          <div
            class="image-reader-item margin-tb-sm"
            :style="{ width: `${width}px`, height: `${height}px` }"
            v-show="limit === 0 || value.length < limit"
          >
            <md-image-reader
              @select="handleReaderSelect"
              @complete="handleReaderComplete"
              @error="handleReaderError"
              is-multiple
            ></md-image-reader>
            <md-icon name="camera" size="lg" :style="{ top: `${showTip ? '40' : '50'}%` }"></md-icon>
            <p v-if="showTip">点击上传</p>
          </div>
        </div>
      </div>
    </validation-provider>
    <md-image-viewer v-model="isViewerShow" :list="viewerImgs" :initial-index="viewerIndex"> </md-image-viewer>
  </div>
</template>

<script>
import imageProcessor from 'mand-mobile/components/image-reader/image-processor'
import { ValidationProvider } from 'vee-validate'
import { imageUpload } from '@/api/upload'

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'lg',
    },
    showTip: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number,
      default: 0,
    },
  },
  components: { ValidationProvider },
  model: { prop: 'value', event: 'change' },
  data() {
    return {
      isViewerShow: false,
      viewerIndex: 0,
    }
  },
  computed: {
    width() {
      if (this.size === 'sm') {
        return 50
      }
      return 100
    },
    height() {
      if (this.size === 'sm') {
        return 50
      }
      return 100
    },
    viewerImgs() {
      return this.value.map(item => this.$options.filters['imgSrc'](item))
    },
  },
  methods: {
    handleReaderSelect() {
      this.$toast.loading('图片读取中...')
    },
    handleReaderComplete(name, { dataUrl, file }) {
      this.$toast.hide()
      imageProcessor({
        dataUrl,
        width: 800,
        height: 800,
        quality: 1,
      }).then(({ blob }) => {
        const formData = new FormData()
        formData.append('file', blob, file.name)
        imageUpload(formData).then(res => {
          const imgList = [...this.value, res.data.url]
          this.$emit('change', imgList)
        })
      })
    },
    handleReaderError(name, { msg }) {
      this.$toast.failed(msg)
    },
    handleDeleteImage(index) {
      const imgList = [...this.value]
      imgList.splice(index, 1)
      this.$emit('change', imgList)
    },
    handleView(index) {
      this.isViewerShow = true
      this.viewerIndex = index
    },
    _validate() {
      this.$refs.vImgUpload.validate(this.value)
    },
  },
  watch: {
    value() {
      this._validate()
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../styles/variable'
.image-reader-item
  position relative
  background $gray1
  box-shadow 0 5px 20px rgba(197, 202, 213, 0.5)
  box-sizing border-box
  list-style none
  border-radius 4px
  background-size cover
  .md-icon
    position absolute
    left 50%
    transform translate(-50%, -50%)
    color $gray6
  p
    position absolute
    top 50%
    left 0
    width 100%
    margin-top 15px
    font-size 22px
    color $gray6
    text-align center
  &-preview
    position relative
    width 100px
    height 100px
    img
      width 100%
      height 100%
      object-fit cover
      box-shadow 0 5px 20px rgba(197, 202, 213, 0.5)
      box-sizing border-box
      list-style none
      border-radius 4px
    &-del
      position absolute
      top 0
      right 0
      z-index 3
      opacity 0.8
      .md-icon-close
        font-size 24px
.image-reader-title
  &::before
    content '* '
    color $red6
.error
  font-size 24px
  color $red6
  margin-top 20px
</style>
