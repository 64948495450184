import request from '@/utils/request'

export function imageUpload(params) {
  return request({
    url: '/upload/image',
    method: 'post',
    data: params,
  })
}

export function fileUpload(params) {
  return request({
    url: '/upload/file',
    method: 'post',
    data: params,
  })
}
