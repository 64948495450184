<template>
  <div>
    <div class="container">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>
    <md-tab-bar class="tab-bar" v-model="current" :items="items" :has-ink="false" @change="handleChange">
      <template #item="{ item }">
        <div class="row row-direction-column row-justify-center row-align-center">
          <md-icon :name="item.icon" size="lg" />
          <span class="font-sm margin-top-xs" v-text="item.label" :style="{ minWidth: '48px', textAlign: 'center' }"></span>
        </div>
      </template>
    </md-tab-bar>
    <md-dialog title="联系客服" :closable="false" v-model="slotDialog.open" :btns="slotDialog.btns">
      <div class="dialog-banner" slot="header">
        <img :src="qrcode" alt="" width="100%" />
      </div>
      {{ `请扫描上方二维码添加客服微信，或拨打客服电话：${this.phone}` }}
    </md-dialog>
  </div>
</template>
<script>
import * as settingApi from '@/api/setting'

export default {
  data() {
    return {
      items: [
        { name: 0, label: '首页', icon: 'home', routeName: 'home' },
        { name: 1, label: '规范', icon: 'info', routeName: 'norm-menu' },
        { name: 2, label: '计算卡', icon: 'card-bag', routeName: 'calc-card' },
        // { name: 3, label: '在线咨询', icon: 'service', routeName: '' },
        // { name: 4, label: '我的', icon: 'user', routeName: 'user' },
      ],
      current: 0,
      phone: '',
      qrcode: '',
      slotDialog: {
        open: false,
        btns: [
          {
            text: '好的',
          },
        ],
      },
      isPro: this.$store.getters['user/isPro'],
      isSuperPro: this.$store.getters['user/isSuperPro'],
      isTest: this.$store.getters['user/isTest'],
    }
  },
  created() {
    settingApi.show(1).then((res) => {
      this.phone = res.data.phone
      this.qrcode = process.env.VUE_APP_BASE_BACK_URL + res.data.qrcode[0].url
    })
  },
  methods: {
    handleChange(item) {
      // if (item.name === 2) {
      //   if ((this.isPro || this.isTest) && this.$route.name !== item.routeName) {
      //     this.$router.push({ name: item.routeName })
      //   } else {
      //     this.$toast.hide()
      //     this.$toast.info('企业版提供该功能')
      //     this.$nextTick(() => this._selectIndex())
      //   }
      // } else if (item.name === 3) {
      //   // this.$dialog.alert({
      //   //   title: '提示',
      //   //   icon: 'service',
      //   //   content: `请拨打电话：${this.phone}`,
      //   // })
      //   this.slotDialog.open = true
      //   this.$nextTick(() => this._selectIndex())
      // } else {
      //   if (this.$route.name !== item.routeName) {
      //     this.$router.push({ name: item.routeName })
      //   }
      // }
      if (this.$route.name !== item.routeName) {
        this.$router.push({ name: item.routeName })
      }
    },
    _selectIndex() {
      this.items.forEach((item) => {
        if (item.routeName === this.$route.name) {
          this.current = item.name
        }
      })
    },
  },
  watch: {
    $route() {
      this._selectIndex()
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm._selectIndex()
    })
  },
}
</script>

<style lang="stylus" scoped>
.container
  padding-bottom 100px
.tab-bar
  position fixed
  bottom 0
  left 0
  right 0
  z-index 99
.md-tab-bar
  padding-left 0
  padding-right 0
</style>
