export const boolStatus = value => {
  const statusMap = {
    true: '是',
    false: '否',
  }
  return statusMap[value]
}

export const answerText = value => {
  const map = {
    true: '存在此问题',
    false: '没有此问题',
    '': '未勾选',
    null: '未勾选',
  }
  return map[value]
}

export const answerColor = value => {
  const map = {
    true: '#f5222d',
    false: '#52c41a',
    '': '#8c8c8c',
    null: '#8c8c8c',
  }
  return map[value]
}

export const imgSrc = value => {
  return process.env.VUE_APP_BASE_BACK_URL + value
}
