import Vue from 'vue'
import {
  Button,
  Field,
  CellItem,
  Dialog,
  Icon,
  Radio,
  ImageReader,
  Tag,
  Toast,
  InputItem,
  Popup,
  PopupTitleBar,
  ScrollView,
  Tabs,
  TabPane,
  ImageViewer,
  ActionBar,
  Progress,
  Bill,
  DetailItem,
  WaterMark,
  TabBar,
  TabPicker,
  FieldItem,
  CheckBox,
  CheckGroup,
  Swiper,
  SwiperItem,
  ResultPage,
  Skeleton,
  Captcha,
  Picker,
  ActionSheet,
  Selector,
  RadioList,
  Check,
  CheckList,
} from 'mand-mobile'
import NavBar from '@/components/NavBar/Index.vue'
import Search from '@/components/Search/Index.vue'
import VField from '@/components/VField/Index.vue'
import VInput from '@/components/VInput/Index.vue'
import VImgUpload from '@/components/VImgUpload/Index.vue'
import { ValidationObserver } from 'vee-validate'

Vue.component(Button.name, Button)
Vue.component(Field.name, Field)
Vue.component(CellItem.name, CellItem)
Vue.component(Dialog.name, Dialog)
Vue.component(Icon.name, Icon)
Vue.component(Radio.name, Radio)
Vue.component(ImageReader.name, ImageReader)
Vue.component(Tag.name, Tag)
Vue.component(InputItem.name, InputItem)
Vue.component(Popup.name, Popup)
Vue.component(PopupTitleBar.name, PopupTitleBar)
Vue.component(ScrollView.name, ScrollView)
Vue.component(Tabs.name, Tabs)
Vue.component(TabPane.name, TabPane)
Vue.component(ImageViewer.name, ImageViewer)
Vue.component(ActionBar.name, ActionBar)
Vue.component(Progress.name, Progress)
Vue.component(Bill.name, Bill)
Vue.component(DetailItem.name, DetailItem)
Vue.component(WaterMark.name, WaterMark)
Vue.component(TabBar.name, TabBar)
Vue.component(TabPicker.name, TabPicker)
Vue.component(FieldItem.name, FieldItem)
Vue.component(CheckBox.name, CheckBox)
Vue.component(CheckGroup.name, CheckGroup)
Vue.component(Swiper.name, Swiper)
Vue.component(SwiperItem.name, SwiperItem)
Vue.component(ResultPage.name, ResultPage)
Vue.component(Skeleton.name, Skeleton)
Vue.component(Captcha.name, Captcha)
Vue.component(Picker.name, Picker)
Vue.component(ActionSheet.name, ActionSheet)
Vue.component(Selector.name, Selector)
Vue.component(RadioList.name, RadioList)
Vue.component(Check.name, Check)
Vue.component(CheckList.name, CheckList)

Vue.component('NavBar', NavBar)
Vue.component('Search', Search)
Vue.component('VField', VField)
Vue.component('VInput', VInput)
Vue.component('VImgUpload', VImgUpload)
Vue.component('ValidationObserver', ValidationObserver)

Vue.prototype.$toast = Toast
Vue.prototype.$dialog = Dialog
