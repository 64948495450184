import Vue from 'vue'
import { login, logout, me } from '@/api/user'

export default {
  namespaced: true,
  state: {
    info: {},
    token: '',
    openidKey: '',
    isPro: false,
    isSuperPro: false,
    isTest: false,
  },
  getters: {
    info(state) {
      return state.info
    },
    token(state) {
      return state.token
    },
    isPro(state) {
      return state.isPro
    },
    isSuperPro(state) {
      return state.isSuperPro
    },
    isTest(state) {
      return state.isTest
    },
  },
  actions: {
    Login({ commit }, params) {
      return new Promise((resolve, reject) => {
        login(params)
          .then(res => {
            commit('setToken', { token: res.data.access_token, expiresIn: res.data.expires_in })
            resolve(res)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    Logout({ commit }) {
      return new Promise(resolve => {
        logout()
          .then(() => {
            resolve()
          })
          .catch(() => {
            resolve()
          })
          .finally(() => {
            commit('setToken', '')
            Vue.ls.remove('token')
          })
      })
    },
    // 获取用户相关信息
    GetUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        me()
          .then(res => {
            commit('setUserInfo', res.data)
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  },
  mutations: {
    setToken(state, payload) {
      Vue.ls.set('token', payload.token, payload.expiresIn * 1000)
      state.token = payload.token
    },
    setUserInfo(state, payload) {
      state.info = payload
      state.isPro = payload.is_pro
      state.isSuperPro = payload.is_super_pro
      state.isTest = payload.is_test
    },
  },
}
