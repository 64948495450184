<template>
  <validation-provider :name="$attrs.title" :rules="rules" v-slot="{ errors }" :class="{ required: required }">
    <md-input-item
      ref="input"
      v-model="innerValue"
      v-bind="$attrs"
      :error="errors[0]"
      :class="{
        unrequired: !required,
      }"
      v-on="$listeners"
    ></md-input-item>
  </validation-provider>
</template>
<script>
import { ValidationProvider } from 'vee-validate'

export default {
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  inheritAttrs: false,
  components: { ValidationProvider },
  data() {
    return {
      innerValue: '',
    }
  },
  created() {
    if (this.value || this.value === 0) {
      this.innerValue = this.value
    }
  },
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal)
    },
    value(newVal) {
      this.innerValue = newVal
    },
  },
}
</script>
<style lang="stylus" scoped>
@import '../styles/variable'
.required
  .md-field-item >>>
    .md-field-item-title
      &::before
        content '* '
        color $red6
.unrequired >>>
  .md-field-item-title
    box-sizing border-box
</style>
