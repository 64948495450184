import { required, confirmed, length, email, min_value, max_value } from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'
import { codeIsExsit } from '@/api/user'

extend('required', {
  ...required,
  message: field => `${field}为必填项`,
})

extend('email', {
  ...email,
  message: field => `${field}格式不正确`,
})

extend('confirmed', {
  ...confirmed,
  message: field => `${field}格式不正确`,
})

extend('length', {
  ...length,
  message: (field, params) => `${field}必须为${params.length}位`,
})

extend('min_value', {
  ...min_value,
  message: (field, params) => `${field}最小值为${params.min}`,
})

extend('max_value', {
  ...max_value,
  message: (field, params) => `${field}最大值为${params.max}`,
})

// 不能含有中文
extend('no-zh-CN', {
  validate: val => /^[^\u4e00-\u9fa5]+$/.test(val),
  message: field => `${field}不能输入中文`,
})

// 只能有数字或字母
extend('only-num-let', {
  validate: val => /^[A-Za-z0-9]+$/.test(val),
  message: field => `${field}只能输入数字、字母`,
})

// 只能有数字或字母
extend('only-num', {
  validate: val => /^\d+(\.\d+)?$/.test(val),
  message: field => `${field}只能输入数字`,
})

// 验证手机号码
extend('phone', {
  validate: val => /^1[3456789]\d{9}$/.test(val),
  message: field => `${field}格式不正确`,
})

extend('codeIsExsit', {
  validate: val => {
    if (val.length === 5) {
      return codeIsExsit({ code: val }).then(res => {
        if (res.data.is_exsit) {
          return true
        } else {
          return false
        }
      })
    } else {
      return true
    }
  },
  message: () => '邀请码不存在',
})
